import React from "react";
import { Helmet } from "react-helmet";
import Section from "../components/Section";
import Title from "../components/Title";

class NotFound extends React.Component {
  render() {
    return (
      <Section>
        <Helmet>
          <title>Lunsford Ridge Farm | Not Found</title>
          <meta property="og:title" content="Lunsford Ridge Farm | Not Found" />
        </Helmet>
        <Title>Page Not Found</Title>
      </Section>
    );
  }
}

export default NotFound;
